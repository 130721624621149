<template>
  <div class="game-selector">
    <select v-model="selectedGame" @change="notifyGameChange" class="game-select">
      <option v-for="game in games" :key="game" :value="game">{{ game }}</option>
    </select>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';

export default {
  emits: ['game-changed'],
  setup(props, { emit }) {
    const selectedGame = ref('TapSwap');
    const games = ['TapSwap', 'Blum', 'MemeFi', 'SimpleCoin', 'Cats'];

    const notifyGameChange = () => {
      emit('game-changed', selectedGame.value);
    };

    // Watch for changes to selectedGame and save to localStorage
    watch(selectedGame, (newGame) => {
      localStorage.setItem('selectedGame', newGame);
      notifyGameChange();
    });

    // Load saved selectedGame from localStorage when component mounts
    onMounted(() => {
      const savedGame = localStorage.getItem('selectedGame');
      if (savedGame && games.includes(savedGame)) {
        selectedGame.value = savedGame;
      } else {
        notifyGameChange();  // Emit default selection if no saved game
      }
    });

    return {
      selectedGame,
      games,
      notifyGameChange
    };
  }
};
</script>

<style scoped>
.game-selector {
  display: flex;
  align-items: center;
}

.game-select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
